"use client";

import { Button } from "@/components/ui/button";
import { Menu } from "lucide-react";
import Link from "next/link";
import { useState } from "react";
import Image from "next/image";

interface MainNavProps {
  navLinks: { text: string; href: string }[];
}

const MainNav = ({ navLinks }: MainNavProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <nav className="bg-white border-b">
      <div className="container mx-auto px-4">
          <div className="flex justify-between items-center h-16">
            {/* Logo */}
            <Link href="/" className="flex items-center w-[120px] sm:w-[150px] md:w-[180px]">
              <Image 
                src="/yourfirststore-logo.svg" 
                alt="YourFirstStore Logo" 
                width={200}
                height={200}
                className="w-full h-auto"
                priority
              />
            </Link>

            {/* Desktop Navigation */}
            <div className="hidden md:flex items-center space-x-8">
              {navLinks.map((link, index) => (
                <Link
                  key={index}
                  href={link.href}
                  className="text-gray-600 hover:text-blue-600 transition-colors"
                >
                  {link.text}
                </Link>
              ))}
              <Button asChild className="bg-blue-600 hover:bg-blue-700">
                <Link href="/sign-up">Get Started</Link>
              </Button>
            </div>

            {/* Mobile menu button */}
            <button
              className="md:hidden p-2 rounded-md text-gray-600 hover:text-blue-600"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              <Menu className="h-6 w-6" />
            </button>
          </div>

          {/* Mobile Navigation */}
          {isMenuOpen && (
            <div className="md:hidden py-4">
              <div className="flex flex-col space-y-4">
                {navLinks.map((link, index) => (
                  <Link
                    key={index}
                    href={link.href}
                    className="text-gray-600 hover:text-blue-600 transition-colors"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    {link.text}
                  </Link>
                ))}
                <Button asChild className="bg-blue-600 hover:bg-blue-700">
                  <Link href="/sign-up">Get Started</Link>
                </Button>
              </div>
            </div>
          )}
        </div>
      </nav>
    );
}

export default MainNav;